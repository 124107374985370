import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stubs"
    }}>{`Stubs`}</h1>
    <p>{`Here we describe how stubs are generated and what they are needed for.`}</p>
    <blockquote>
      <p parentName="blockquote">{`📝`}<strong parentName="p">{`Note`}</strong></p>
      <p parentName="blockquote"><strong parentName="p">{`Stub`}</strong>{` is a function which emulates behavior of another function it is written for.
It's often useful to write stubs for functions from another module that you don't want to test.`}</p>
    </blockquote>
    <h3 {...{
      "id": "when-stubs-are-used"
    }}>{`When stubs are used`}</h3>
    <p>{`UTBot generates stubs for every function in the project first time you open it and synchronize each time before test
generation.`}</p>
    <p>{`First time you open the project you will see `}<inlineCode parentName="p">{`UTBot is generating stubs for project...`}</inlineCode>{` message. It means that for each
file in your project UTBot generates file which contains all non-static functions signatures from the source file. Stubs
can be used as substitutes for calling functions from another module. Consider the following project structure:`}</p>
    <pre><code parentName="pre" {...{}}>{`executable
|---- lib1.a
|     |---- A.c
|     +---- B.c
|---- lib2.a
|     |---- C.c
`}</code></pre>
    <p>{`If we run test generation for `}<inlineCode parentName="p">{`C.c`}</inlineCode>{`, all functions from `}<inlineCode parentName="p">{`lib1.a`}</inlineCode>{` will be replaced with their stubs on build step. So, if
the function `}<inlineCode parentName="p">{`foo`}</inlineCode>{` from `}<inlineCode parentName="p">{`C.c`}</inlineCode>{` calls the function `}<inlineCode parentName="p">{`bar`}</inlineCode>{` from `}<inlineCode parentName="p">{`A.c`}</inlineCode>{`, then stub for the function `}<inlineCode parentName="p">{`bar`}</inlineCode>{` will be invoked
instead. Note that you can still generate tests using the exact definition of `}<inlineCode parentName="p">{`bar`}</inlineCode>{`; for that, you will need to
unmark `}<inlineCode parentName="p">{`Use Stubs`}</inlineCode>{` checkbox in `}<a parentName="p" {...{
        "href": "vscode-extension-settings#use-stubs"
      }}>{`settings`}</a>{`.`}</p>
    <h3 {...{
      "id": "stub-file-example"
    }}>{`Stub file example`}</h3>
    <p>{`Stub files can be found in `}<inlineCode parentName="p">{`tests/stubs`}</inlineCode>{` folder. This is an example of stub file generated
for `}<inlineCode parentName="p">{`c-example/lib/dependent_functions.c`}</inlineCode>{` from
the `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotCpp/tree/main/integration-tests/c-example"
      }}>{`example project`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`// 1624618650000000000
// Please, do not change the line above

#ifdef
KLEE_MODE
extern void klee_make_symbolic(void *addr, unsigned long long nbytes, const char *name);
#endif
#include
"dependent_functions_stub.h"

#define
NULL ((void*)0)

int double_max_symbolic;
int double_max(int a, int b) {
static int firstTimeCall = 1;
#ifdef
KLEE_MODE
if (firstTimeCall == 1) {
firstTimeCall = 0;
klee_make_symbolic(&double_max_symbolic, sizeof(double_max_symbolic), "double_max_symbolic");
}
#endif
return double_max_symbolic;
}

`}</code></pre>
    <p>{`The first line contains timestamp of stub file creation. It is required for synchronization with the source code, so,
please, do not modify this line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`// 1619438023000000000
`}</code></pre>
    <p>{`Then, if a `}<inlineCode parentName="p">{`KLEE_MODE`}</inlineCode>{` macro is passed during preprocessing, we add a KLEE declaration to allow us to use symbolic
return value. `}<inlineCode parentName="p">{`KLEE_MODE`}</inlineCode>{` serves for both test generation and test running purposes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`#ifdef KLEE_MODE
extern void klee_make_symbolic(void *addr, unsigned long long nbytes, const char *name);
#endif
`}</code></pre>
    <p>{`This variable stores return value of the stub function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`int double_max_symbolic;
`}</code></pre>
    <p>{`Stub function signature is always the same as the signature of the source function. If it's not (for example, if the
function was modified), then UTBot will synchronize them by rewriting stub function with the new one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`int double_max(int a, int b)
`}</code></pre>
    <p>{`If UTBot uses stubs, then `}<inlineCode parentName="p">{`KLEE_MODE`}</inlineCode>{` is defined, and the return value is made symbolic. This way KLEE can decide what
values to return to satisfy the execution paths:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`static int firstTimeCall = 1;
#ifdef KLEE_MODE
    if (firstTimeCall == 1) {
        firstTimeCall = 0;
        klee_make_symbolic(&double_max_symbolic, sizeof(double_max_symbolic), "double_max_symbolic");
    }
#endif
`}</code></pre>
    <h3 {...{
      "id": "stub-headers"
    }}>{`Stub headers`}</h3>
    <p>{`For each stub, a header is generated. It contains definitions of types and structures used in function headers, allowing
stubs to be compilable. Stub headers may contain definitions fetched from system headers and may look obscure, but
usually you will not modify them.`}</p>
    <blockquote>
      <p parentName="blockquote">{`📝`}<strong parentName="p">{`Note`}</strong></p>
      <p parentName="blockquote">{`You can change the stub function body however you want. UTBot saves custom code inside the function while synchronizing. However, if the source function signature changes, then stub function will be completely rewritten.`}</p>
    </blockquote>
    <h3 {...{
      "id": "tests-with-stubs"
    }}>{`Tests with stubs`}</h3>
    <p>{`For the tests that use stubs, the return values are generated in a such way that the code coverage is maximized. For
example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`extern "C" char pointerToPointer_symbolic;

TEST(regression, calc_two_numbers_f_test_1)
{
    // Initialize symbolic stubs
    pointerToPointer_symbolic = '\\x10';

    // Construct input
    char a = 'c';
    char b = 'p';

    // Expected output
    int expected = 2;

    // Trigger the function
    int actual = calc_two_numbers_f(a, b);

    // Check results
    EXPECT_EQ(expected, actual);
}

TEST(regression, calc_two_numbers_f_test_2)
{
    // Initialize symbolic stubs
    pointerToPointer_symbolic = 'b';

    // Construct input
    char a = 'c';
    char b = 'b';

    // Expected output
    int expected = 1;

    // Trigger the function
    int actual = calc_two_numbers_f(a, b);

    // Check results
    EXPECT_EQ(expected, actual);
}
`}</code></pre>
    <p>{`Here `}<inlineCode parentName="p">{`pointerToPointer_symbolic`}</inlineCode>{` stores return value for `}<inlineCode parentName="p">{`pointerToPointer`}</inlineCode>{` stub function.`}</p>
    <h3 {...{
      "id": "modifying-stubs"
    }}>{`Modifying stubs`}</h3>
    <p>{`As it was noted, it is easy to rewrite UTBot stubs into anything you want them to be. You should modify only function
bodies and not touch header include, or `}<inlineCode parentName="p">{`KLEE_MODE`}</inlineCode>{` ifdefs. Consider the simplest example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`// 1624618650000000000
// Please, do not change the line above

#ifdef
KLEE_MODE
extern void klee_make_symbolic(void *addr, unsigned long long nbytes, const char *name);
#endif
#include
"dependent_functions_stub.h"

#define
NULL ((void*)0)

int double_max_symbolic;
int double_max(int a, int b) {
return a;
}
`}</code></pre>
    <p>{`In such a case, UTBot will generate tests for functions from other CMake modules implying
that `}<inlineCode parentName="p">{`double_max(int a, int b) = a`}</inlineCode>{`.`}</p>
    <p>{`However, you can also use symbolic variable power when modifying stubs. Suppose that you want your function not to
return any value, but perform some checks on the arguments prior to that. Also, there may be a special case in that
function that is highly important to be reflected in the stub. In that case, you can insert those checks in the stub,
and, if they succeed, return a symbolic value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`// 1624618650000000000
// Please, do not change the line above

#ifdef
KLEE_MODE
extern void klee_make_symbolic(void *addr, unsigned long long nbytes, const char *name);
#endif
#include
"dependent_functions_stub.h"

#define
NULL ((void*)0)

int double_max_symbolic;
int double_max(int a, int b) {
if (a == 100 && a > b) {
return 100;
}
static int firstTimeCall = 1;
#ifdef
KLEE_MODE
if (firstTimeCall == 1) {
firstTimeCall = 0;
klee_make_symbolic(&double_max_symbolic, sizeof(double_max_symbolic), "double_max_symbolic");
}
#endif
return double_max_symbolic;
}
`}</code></pre>
    <p>{`By this change, `}<inlineCode parentName="p">{`double_max(a, b)`}</inlineCode>{` will preserve its behaviour if a certain condition holds. This principle can be used
to achieve the similarity of the stub and the original code while leaving out big parts of code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      